import { Vue, Component, Watch } from 'vue-property-decorator';

@Component
export default class TablePageMixin extends Vue {
  getList() {}
  page: number = 1;
  pageSize: number = 10;
  total: number = 0;
  pageSizeOptions: string[] = ['10', '20', '50', '100'];
  loading: boolean = false;
  tableSize: string = 'default';

  onShowSizeChange(current: number, size: number) {
    this.pageSize = size;
  }

  showTotal = (total: number) => `共${total}条记录`;

  @Watch('page')
  onPageChange = () => this.getList();
  @Watch('pageSize')
  onpageSizeChange = () => this.getList();

  selectedRows: any[] = [];
  selectedRowKeys: string[] = [];
  onSelectChange(keys: string[], rows: any) {
    this.selectedRowKeys = keys;
    this.selectedRows = rows;
  }

  initPage() {
    this.page = 1;
    this.pageSize = 10;
  }

  filter: any = {};
  excuteFilter(filter: object) {
    this.page = 1;
    this.filter = filter;
    this.getList();
  }
  resetFilter() {
    this.filter = {};
    this.page = 1;
    this.getList();
  }
}
